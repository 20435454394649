import React from "react"
import ReactHtmlParser from "react-html-parser"
import { navigate } from "@reach/router"

import {
  TncSection,
  TncH2,
  Wrapper,
  TncH4,
  TncButton,
  TncBr,
  FormDiv,
  Ornament,
} from "./AffiliateStyle"
import { Container, Form } from "react-bootstrap"
import bgBuilding from "../../images/bg-building-footer.svg"
import iconDownload from "../../images/icon-affiliate-download.svg"
import OrnamentImg from "../../images/ornament-bird-white-new.png"
import styled from "styled-components"

function AffiliateTnc({ propsData, propsLink }) {
  const { Title1, Title2, Desc, Button1, Button2, Terms } = propsData.Tnc

  const handleSubmit = () => {
    navigate(propsLink.AffiliateForm)
  }

  const newDesc = ReactHtmlParser(Desc)

  return (
    <>
      <a
        id="tnc"
        style={{ display: "block", paddingTop: "80px", marginTop: "-80px" }}
      ></a>
      <Container>
        <TncSection background={bgBuilding}>
          <TncH2>{Title1}</TncH2>
          <Wrapper>
            <TncH4>{Title2}</TncH4>
            {newDesc}
          </Wrapper>
          <FormDiv>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Check
                  required
                  label={Terms}
                  style={{ color: "#fff", fontFamily: "Typefez-reg" }}
                  feedback="Please check this box if you want to proceed."
                />
              </Form.Group>
              <TncButton type="submit">
                {Button1}
              </TncButton>
            </Form>
          </FormDiv>
        </TncSection>
      </Container>
    </>
  )
}

export default AffiliateTnc